import React from 'react';
import '../styles/EmailConfirmationPopup.css';

const EmailConfirmation = ({ email, onClose }) => {
  return (
    <div className="email-confirmation-overlay">
      <div className="email-confirmation-modal" style={{ 
        backgroundColor: '#111',
        border: '1px solid rgba(255, 215, 0, 0.1)',
        color: '#fff'
      }}>
        <h2 style={{ 
          color: '#ffd700',
          fontSize: '1.5rem',
          marginBottom: '1rem'
        }}>Check Your Email</h2>
        <p style={{ color: '#888', marginBottom: '1rem' }}>Verify your account</p>
        <p style={{ color: '#fff', marginBottom: '1.5rem' }}>
          We've sent a confirmation link to<br />
          <strong style={{ color: '#ffd700' }}>{email}</strong>
        </p>
        <button className="auth-button" onClick={onClose}>
          Got it
        </button>
      </div>
    </div>
  );
};

export default EmailConfirmation;